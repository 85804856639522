import { CardFooterLeft } from '../bootstrap/Card';
import Button, { ButtonGroup } from '../bootstrap/Button';
import { useTranslation } from "react-i18next";

const containerOptionTools = (props) => {
    const { hierarchyRootOption, editMode, isMobile, type, setEditMode, setType, setActiveSaveButton, setActivePositionButtons, activePositionButtons } = { ...props };
    const { t } = useTranslation('menu');

    return (
        <div id="containerOptionTools"
            //className={`center-content$  ${isMobile ? 'isMobile' : ''} ${activePositionButtons ? ' hidden' : ''}`}
            className={`center-content$ isMobile ${activePositionButtons ? ' hidden' : ''}`}
        >
            {!hierarchyRootOption &&
                <CardFooterLeft
                    className={`${editMode ? 'hidden' : ''}`}
                >
                    <ButtonGroup
                    //isVertical={isMobile}
                    >
                        <Button
                            color="primary"
                            icon="info"
                            isLink
                            {...(type === "details" ? { isLight: true } : {})}
                            onClick={() => {
                                setEditMode(false)
                                setActiveSaveButton(false)
                                setType("details")
                                setActivePositionButtons(false)
                            }
                            }
                        >
                            <span>
                                {t("Details")}
                            </span>
                        </Button>
                        <Button
                            color="primary"
                            icon="View_in_ar"
                            isLink
                            {...(type === "model" ? { isLight: true } : {})}
                            onClick={() => {
                                setEditMode(false)
                                setType("model")
                                setActiveSaveButton(false)
                                setActivePositionButtons(false)
                            }
                            }
                        >
                            <span>{t("Models")}</span>
                        </Button>
                        <Button
                            color="primary"
                            icon="Sensors"
                            isLink
                            {...(type === "sensor" ? { isLight: true } : {})}
                            onClick={() => {
                                setEditMode(false)
                                setType("sensor")
                                setActiveSaveButton(false)
                                setActivePositionButtons(false)

                            }}
                        >
                            <span>{t("Sensors")}</span>
                        </Button>
                        <Button
                            isLink
                            color="primary"
                            icon="border_clear"
                            {...(type === "volume" ? { isLight: true } : {})}
                            onClick={() => {
                                setEditMode(false)
                                setType("volume")
                                setActiveSaveButton(false)
                                setActivePositionButtons(false)
                            }}
                        >
                            <span>{t("Volumes")}</span>
                        </Button>
                        <Button
                            isLink
                            color="primary"
                            icon="LocalOffer"
                            {...(type === "tag" ? { isLight: true } : {})}
                            onClick={() => {
                                setEditMode(false)
                                setType("tag")
                                setActiveSaveButton(false)
                                setActivePositionButtons(false)
                            }}
                        >
                            <span>{t("Tags")}</span>
                        </Button>
                        <Button
                            isLink
                            color="primary"
                            icon="segment"
                            {...(type === "hierachy" ? { isLight: true } : {})}
                            onClick={() => {
                                setEditMode(false)
                                setType("hierachy")
                                setActiveSaveButton(false)
                                setActivePositionButtons(false)
                            }}
                        >
                            <span>{t("Hierarchy")}</span>
                        </Button>
                    </ButtonGroup>
                </CardFooterLeft>
            }


        </div>
    );
}

export default containerOptionTools;
