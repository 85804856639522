import axios, * as others from "axios";

let isModalCreated = false;
const API_POST_LOGIN = process.env.REACT_APP_SERVER_DOMAIN + "/api/auth/";
const API_POST_LOGIN_MAIL =
process.env.REACT_APP_SERVER_DOMAIN + "/api/auth/email";
const API_POST_LOGIN_PASSWORD =
process.env.REACT_APP_SERVER_DOMAIN + "/api/auth/password";

const API_POST_LOGIN_MAIL_USERNAME =
process.env.REACT_APP_SERVER_DOMAIN + "/api/auth/emailOrUsername";

const API_POST_LOGIN_USER_NAME =
process.env.REACT_APP_SERVER_DOMAIN + "/api/login/";


const API_URL_CHECK_TOKEN = process.env.REACT_APP_DOMAIN + "/api/checkToken";


let config= {
  headers: {
    "Content-Type": "application/json",
   'x-auth-token':''
   
  },
  /*
  validateStatus: function (status: any) {
    return status < 500; // Resolve only if the status code is less than 500
  },
  */
};

type User = {
  UUID: number;
  email: string;
  username: string;
  status: boolean;
};
type GetUsersResponse = {
  data: User[];
};

const login = (username: string, password: string) => {
  return axios.post(API_POST_LOGIN_USER_NAME, { username, password }, config)
    .then( (response: any) =>  response.data)
    .catch((error: any) => error.response.data);
};

const loginMailOrUsername = function (userName: string) {
  return new Promise<any>((resolve, reject) => {
    axios
      .post(API_POST_LOGIN_MAIL_USERNAME, { userName }, config)
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  });
};

const loginPassword = function (password: string, user: string) {
  return new Promise<any>((resolve, reject) => {
    axios
      .post(API_POST_LOGIN_PASSWORD, { password, user }, config)
      .then(function (response: any) {
        resolve(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  });
};

const logOut=function(){
  localStorage.clear();
  showModal("Session expired");
  setTimeout(() => {window.location.href = "auth-pages/login";}, 3000);
  
}

const checkOut=function (){

}

const getToken = () => {
    const storageToken = localStorage.getItem("token");
    let token='';
     token = storageToken !== undefined ? storageToken+'' : '';

    config = {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token+'',
        },
    };

     if (typeof token === 'undefined' || token === null) {
        logOut();
    }
    try {

        axios.post(API_URL_CHECK_TOKEN, JSON.stringify({}), config).then(res => {
            console.log({ authMsg: res.data.msg })
            if (res.data.msg !== "ok") {
                logOut();
            }
        }).catch(error => {
            console.error('Error en la petición:', error); // console.log en caso de error
            logOut();
        })


    } catch (error) {
        logOut();
    }

};


function showModal(message:string) {

    if (document.getElementById("sessionModal")) {
        return; 
    }
    const modal = document.createElement('div');
    modal.id="sessionModal";
    modal.style.position = 'fixed';
    modal.style.top = '50%';
    modal.style.left = '50%';
    modal.style.transform = 'translate(-50%, -50%)';
    modal.style.padding = '20px';
    modal.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
    modal.style.color = 'white';
    modal.style.borderRadius = '10px';
    modal.style.zIndex = '1000';
    modal.textContent = message;
    document.body.appendChild(modal);
    isModalCreated = true;
    setTimeout(() => {
        document.body.removeChild(modal);
        isModalCreated = false; //
    }, 2500);
    
   
}



const authServices = {
  login,
  loginMailOrUsername,
  loginPassword,
  logOut,
  getToken,
  config,
  showModal,
  isModalCreated
};



export default authServices;
