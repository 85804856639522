import React, { useEffect, useState } from 'react';
import Button, { ButtonGroup } from './bootstrap/Button';
import { useContext } from 'react';
import { OverlayContext } from '../contexts/OverlayContext';
import { MatterportContext } from '../contexts/MatterportContext';

const CurrentNodeActions = (props) => {
    const { isMobil } = props;
    const [role, setRole] = useState();
    const [isDisabled, setIsDisabled] = useState(false);

    const [MatterTags, setMatterTags] = useState([]);
    const handleClick = () => {
        // Acción principal del botón
        console.log("Botón clicado");
        // Desactivar el botón temporalmente
        setIsDisabled(true);
        // Rehabilitar el botón después de 1 segundo
        setTimeout(() => {
            dispatchCustomEvent("positionEvent", currentNodeSelected);
            setIsDisabled(false);
        }, 1000); // Cambia el tiempo según sea necesario
    };

    const {
        currentNodeSelected
    } = useContext(OverlayContext);
    const {
        matterportSdk
    } = useContext(MatterportContext);
    function dispatchCustomEvent(eventName, data) {
        const event = new CustomEvent(eventName, { detail: data });
        console.log("EVENTO")
        window.dispatchEvent(event);

        if (eventName === "positionEvent") {
            setTimeout(function () {
                console.log('Han pasado 1 segundo');
                // clickIcon = false;
            }, 1000);
        }//css-kdwx3d
    }
    useEffect(() => {
        console.log({ currentNodeSelected })
    }, [currentNodeSelected])

    useEffect(() => {
        // Obtenemos el valor de la variable de localStorage
        const role = localStorage.getItem("role");
        //  setSaveIsEnabled(false);
        // setEditPanel(false);
        // Guardamos el valor en el estado
        setRole(role);
    }, []);

    return (
        <ButtonGroup
            isVertical={isMobil}
            id="currentNodeActions"
            className={`${isMobil ? 'isMobil' : ''}`}

        >
            <Button
                disabled={currentNodeSelected.sweepCameraPosition && currentNodeSelected.sweepCameraPosition.sweepointUUID === null ? true : false}
                color={currentNodeSelected.sweepCameraPosition && currentNodeSelected.sweepCameraPosition.sweepointUUID === null ? 'dark' : 'primary'}
                data-role="admin"
                className={`${isDisabled ? 'no-pointer-events' : ''}`}
                icon="Place"
                onClick={() => {
                    handleClick();
                }}
            >
            </Button>
            {role === "Admin" ? (
                <Button
                    color="primary"
                    icon="construction"
                    onClick={() => {
                        dispatchCustomEvent("nodeOptionEvent", currentNodeSelected);
                        dispatchCustomEvent("detailsClickEvent", { ...currentNodeSelected, detailsClicked: true });
                    }}
                >
                </Button>

            ) : <></>}
            <Button
                color="primary"
                icon="Info"
                onClick={() => {
                    dispatchCustomEvent("detailsClickEvent", { ...currentNodeSelected, detailsClicked: true });
                    console.log("ID");
                }}
            ></Button>

        </ButtonGroup>
    );
}

export default CurrentNodeActions;
