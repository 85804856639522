import React, { useContext, useEffect } from 'react'
import Button from '../bootstrap/Button'
import { OverlayContext } from '../../contexts/OverlayContext'
import { useTranslation } from "react-i18next";

function SaveBar() {
    const {
        activeSaveButton,
        setisSaveClicked,
        isSaveClicked
    } = useContext(OverlayContext);

    const { t } = useTranslation('menu');

    useEffect(() => {
        if (isSaveClicked) {
            setisSaveClicked(false)
        }
    }, [isSaveClicked])

    return (
        <div id="save-bar" className='footer-button parent-left'>
            <div
            >

                <Button
                    className={activeSaveButton ? '' : 'hidden-element'}
                    color='secondary'
                    icon="Save"
                    onClick={() => setisSaveClicked(true)}
                >
                    {t("Save")}
                </Button>
            </div>


        </div>

    )
}

export default SaveBar