
import React, { useContext, useState, useEffect } from 'react'
import FormGroup from '../bootstrap/forms/FormGroup'
import Label from '../bootstrap/forms/Label'
import Input from '../bootstrap/forms/Input'
import FormText from '../bootstrap/forms/FormText'

import ListGroup, { ListGroupItem } from '../bootstrap/ListGroup'
import Button from '../bootstrap/Button'
import { OverlayContext } from '../../contexts/OverlayContext'
import EditableListItem from './EditableListItem'
import Select from '../bootstrap/forms/Select'
import Checks from '../bootstrap/forms/Checks'
import matterportHelper, { currentPose } from '../../helpers/matterportHelper'
import { useTranslation } from "react-i18next";
import { MatterportContext } from '../../contexts/MatterportContext'
import { defaultModelId } from '../../helpers/matterportHelper'
import { modelsTagList } from '../../helpers/matterportHelper'
import tagsServices from '../../services/tagsServices'
function MatterTagProperty() {
    const {
        editMode,
        setEditMode,
        setActiveSaveButton,
        setReturnView,
        setActivePositionButtons,
        setPositionEditObject,
        positionEditObject,
        currentNodeSelected,
        isSaveClicked,
        setModalState,
        modalState,
        tagData,
        setTagData,
        setUpdateHierarchy,
        setEditModel,
        modelData
    } = useContext(OverlayContext);
    const {
        fbxObjects,
        currentEditObject,
        matterportSdk,
        setCurrenttags,
        currenttags,
        setFbxObjects,

    } = useContext(MatterportContext);

    const { t } = useTranslation('menu');
    const [addNewTag, setAddNewTag] = useState(false);

    const initialTagData = {



        tagName: "tag",
        interactionType: "goToDocumentData",
        interactionReference: "",
        objectTransform: {
            xPosition: 10,
            yPosition: 100,
            zPosition: 111,
            xRotation: 0,
            yRotation: 10,
            zRotation: 0,
            xScale: 0.01,
            yScale: 0.01,
            zScale: 0.01
        },
        sweepCameraPosition: {
            sweepointUUID: "asdzxcas",
            cameraHorizontalRotation: 0.01,
            cameraVerticalRotation: 0.01
        }
    }

    useEffect(() => {
        setTagData(initialTagData)
    }, [])
    useEffect(() => {
        if (isSaveClicked) {
            console.log({ savedFbx: fbxObjects })
            console.log({ tagData })
            tagData.sweepCameraPosition = {

                sweepointUUID: tagData.sweepCameraPosition.sweepointUUID,
                cameraHorizontalRotation: currentPose.rotation.x,
                cameraVerticalRotation: currentPose.rotation.y
            }

            if (!addNewTag) {
                tagsServices.addNewTag(
                    currentNodeSelected._id,
                    tagData,
                    tagData.previousName
                )
                    .then(res => {
                        console.log({ UpdateREs: res })
                        console.log({ currentEditObject, positionEditObject })
                        console.log({ tagData })

                        setReturnView(false);
                        setEditMode(false)
                        setActiveSaveButton(false);
                        console.log({ currentEditObject })
                        //reloadtag(positionEditObject.initialModelData.OID, matterportSdk, positionEditObject.initialModelData, tagData)

                        setModalState({
                            ...modalState,
                            isOpen: !modalState.isOpen,
                            data: {
                                title: t("UpdateTitle"),
                                text: t("UpdatedElementCorrectly"),
                                id: "newtag",
                                actionType: "edit",
                            },
                        });

                    })
                    .catch((err) => {
                        console.error(err)
                        if (!err.response.data.ok) {
                            if (err.response.data.msg == "Already exist name") {
                                setModalState({
                                    ...modalState,
                                    isOpen: !modalState.isOpen,
                                    data: {
                                        title: t("Error"),
                                        text: t("AlreadyExistElement"),
                                        id: "errortag",
                                        actionType: "error",
                                    },
                                });
                            }
                        }
                    })
            } else if (isSaveClicked && addNewTag) {
                notExistsSameName(currentNodeSelected._id, tagData.tagName).then((res) => {
                    if (res === true && !addNewTag) {


                    } else {
                        tagsServices.addNewTag(
                            currentNodeSelected._id,
                            {
                                ...tagData,
                                objectTransform: {
                                    "xPosition": currentEditObject.model.node.obj3D.position.x,
                                    "yPosition": currentEditObject.model.node.obj3D.position.y,
                                    "zPosition": currentEditObject.model.node.obj3D.position.z,
                                    "xScale": currentEditObject.model.node.obj3D.scale.x,
                                    "yScale": currentEditObject.model.node.obj3D.scale.y,
                                    "zScale": currentEditObject.model.node.obj3D.scale.z,
                                    "xRotation": currentEditObject.model.node.obj3D.rotation.x,
                                    "yRotation": currentEditObject.model.node.obj3D.rotation.y,
                                    "zRotation": currentEditObject.model.node.obj3D.rotation.z,
                                }
                            }
                        )
                            .then(res => {
                                setEditMode(false)
                                setActiveSaveButton(false);

                                console.log({ currentEditObject })
                                console.log({ tagData })

                                updatetagnsToShow();

                                matterportHelper.deleteAllSceneObjects(currenttags).then(() => {

                                    changeCurrenttagsStateToEmptyArray().then(() => {
                                        const space = localStorage.getItem("space");
                                        LoadSpacetags(space)
                                            .then((res) => {
                                                console.log("LOAD SPACE")
                                                setCurrenttags(res)

                                            });

                                    })

                                });

                                setModalState({
                                    ...modalState,
                                    isOpen: !modalState.isOpen,
                                    data: {
                                        title: t("NewElement"),
                                        text: t("NewElementSavedCorrectly"),
                                        id: "newSensor",
                                        actionType: "new",
                                    },
                                });
                            })
                            .catch((err) => console.error(err))
                    }

                })
            }


        }
    }, [isSaveClicked, addNewTag])

    return (
        <>
            <FormGroup>
                <Label htmlFor="tagName">
                    {t("Tags")}
                </Label>
                <Input
                    ariaDescribedby="tag name"
                    id="tagName"
                    placeholder="Tag data "
                    className=''
                    value={tagData.tagName}
                    onChange={(e) => {

                        const { name, value } = e.target;
                        setTagData({
                            ...tagData, // Mantener las otras propiedades del estado
                            tagName: value // Actualizar solo la propiedad correspondiente
                        });
                    }

                    }
                />
                <Label htmlFor="selectType">
                    {t("Type")}
                </Label>
                <Select
                    ariaLabel='Type'
                    id="selectType"

                    list={[
                        {
                            text: "html",
                            value: "goToDocumentData"
                        },
                        /*
                        {
                            text: t("displayText"),
                            value: "displayText"
                        },
                        */
                        {
                            text: t("openURL"),
                            value: "openURL"
                        }
                    ]}
                    onChange={(e) => {
                        settagData({ ...tagData, interactionType: e.target.value })
                        setActiveSaveButton(true);
                        if (e.target.value === "goToDocumentData") {
                        }
                    }
                    }
                    title="Type"
                    value={tagData.interactionType}
                />


                <Label htmlFor="tagName">
                    {t("text")}
                </Label>
                <Input
                    ariaDescribedby="Inptu text"
                    id="tagInteractionReference"
                    placeholder="Text "
                    className=''
                    value={tagData.interactionReference}
                    onChange={(e) => {
                        setTagData({ ...tagData, interactionReference: e.target.value })
                        setPositionEditObject(prev => ({
                            ...prev, // Copiar todas las propiedades existentes
                            name: e.target.value, // Actualizar solo esta propiedad
                            type: "tag"
                        }));
                    }
                    }
                />





                <Label htmlFor="editPostion">
                    {t("EditPosition")}
                </Label>
                <br />
                <Button
                    id="editPostion"
                    className='footer-button '
                    color="secondary"
                    onClick={() => {
                        const position = {
                            positionx: matterportHelper.positionInFrontCamera(currentPose.position.x, currentPose.position.z, currentPose.rotation.y).x,
                            positiony: currentPose.position.y,
                            positionz: matterportHelper.positionInFrontCamera(currentPose.position.x, currentPose.position.z, currentPose.rotation.y).z,

                        }
                        matterportHelper.loadEditMattertag(matterportSdk, { position: { x: position.positionx, y: position.positiony, z: position.positionz } }).then(() => {


                            setPositionEditObject(prev => ({
                                ...prev, // Copiar todas las propiedades existentes
                                active: true,
                                type: "tag",
                                name: tagData.tagName,
                                initialModelData: {

                                    ...positionEditObject.initialModelData,
                                    positionx: position.positionx,
                                    positiony: position.positiony,

                                    positionz: position.positionz,

                                    rotationx: 0,
                                    rotationy: 0,
                                    rotationz: 0,
                                    scalex: 1,

                                    scaley: 1,

                                    scalez: 1,

                                    OID: defaultModelId,
                                    animated: 0,
                                    id: "",
                                    interactuable: tagData.interactionType,
                                    interactuable_content: tagData.interactionReference,
                                    interactuable_type: tagData.interactionType,
                                    length: 1,

                                    modelformat: "fbx",
                                    mongoName: currentNodeSelected._id + "/" + tagData.tagName,
                                    originalOID: defaultModelId
                                },
                                editsweepCameraPosition:
                                {
                                    sweepointUUID: currentPose.sweep,
                                    cameraHorizontalRotation: currentPose.rotation.y,
                                    cameraVerticalRotation: currentPose.rotation.x,
                                },
                            }));
                        });
                    }}
                    icon="edit_location"
                >{t("EditPosition")}</Button>

            </FormGroup>
        </>
    )
}

export default MatterTagProperty