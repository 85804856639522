import axios from "axios";

const API_URL_SENSORS = process.env.REACT_APP_DOMAIN + '/api/sensors/';
const API_URL_SENSOR_NAME_EXIST = process.env.REACT_APP_DOMAIN + '/api/sensors/nameExist';
const API_URL_SENSORS_BY_PARENT_UUID = process.env.REACT_APP_DOMAIN + '/api/sensors/byUUID_Parent';
const API_URL_UPDATE_SENSOR_POSITION = process.env.REACT_APP_DOMAIN + '/api/sensors/upadetePosition';
let config = {};
const API_URL_CHECK_TOKEN = process.env.REACT_APP_DOMAIN + "/api/checkToken";
const getToken = () => {
    const storageToken = localStorage.getItem("token");
    const token = storageToken === (null || undefined) ? '' : storageToken;

    config = {
        headers: {
            "x-auth-token": token,
            "Content-Type": "application/json",
        },
    };

    if (token === (null || undefined)) {
        authServices.logOut();
    }
    try {

        axios.post(API_URL_CHECK_TOKEN, JSON.stringify({}), config).then(res => {
            console.log({ authMsg: res.data.msg })
            if (res.data.msg !== "ok") {
                authServices.logOut();
            }
        }).catch(error => {
            console.error('Error en la petición:', error); // console.log en caso de error
            authServices.logOut();
        })


    } catch (error) {
        authServices.logOut();
    }

};


const getTokenDelete = () => {
    const storageToken = localStorage.getItem("token");
    return storageToken === (null || undefined) ? '' : storageToken;
}

const checkSensorName = async (idParent, sensorName) => {
    getToken();
    const response = await axios.post(API_URL_SENSOR_NAME_EXIST, JSON.stringify({
        idParent,
        sensorName
    }), config);

    return response.data;
};
const getSensors = async () => {
    getToken();
    const response = await axios.get(API_URL_SENSORS, config);
    return response.data;
};

const getSensorsByParentUUID = async (idParent) => {
    getToken();
    const response = await axios.post(API_URL_SENSORS, JSON.stringify({ idParent }), config);
    return response.data;
};

const updateSensorPositionByid = async (id, position, rotation) => {
    getToken();
    const response = await axios.put(API_URL_UPDATE_SENSOR_POSITION, JSON.stringify({ id, position, rotation }), config);
    return response.data;
};

const uppdateSensorByParentId = async (idParent, sensorData, previousName) => {
    getToken();
    console.log({ previousName })
    /*
    if (sensorData.sensorName === previousName) {
        previousName = sensorData.sensorName
    }
    */



    const response = await axios.patch(API_URL_SENSORS, JSON.stringify({
        idParent,
        sensorData,
        previousName
    }), config);

    return response.data;
};

const deleteSensor = async (idParent, sensorNameToDelete) => {
    const token = getTokenDelete();
    const response = await axios.delete(API_URL_SENSORS,
        {
            data: { idParent, sensorNameToDelete }, headers: {
                'x-auth-token': token,
                'Content-Type': 'application/json'
            }
        })
    return response.data;
};
const addNewSensor = async (idParent, sensorData) => {
    getToken();
    const response = await axios.put(API_URL_SENSORS, JSON.stringify({
        idParent,
        sensorData,
    }), config);

    return response.data;
};

const sensorsServices = {
    getSensors,
    getSensorsByParentUUID,
    updateSensorPositionByid,
    deleteSensor,
    uppdateSensorByParentId,
    checkSensorName,
    addNewSensor
};

export default sensorsServices;
