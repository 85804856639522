
import axios from "axios";

const API_URL_TAGS_BY_PARENT_UUID = process.env.REACT_APP_DOMAIN + '/api/tags/';
const API_URL_UPDATE_VOLUMEN = process.env.REACT_APP_DOMAIN + '/api/tags/';
const API_URL_CHECK_VOLUME_NAME = process.env.REACT_APP_DOMAIN + "/api/tags/nameExist";
const API_URL_VOLUMES_POSITION = process.env.REACT_APP_DOMAIN + '/api/volumes/upadetePosition';
const API_URL_VOLUME_BY_ID = process.env.REACT_APP_DOMAIN + '/api/volumes/getModelById';
const API_URL_ADD_VOLUME = process.env.REACT_APP_DOMAIN + '/api/volumes/addNewVolume';
const API_URL_GET_ALL_VOLUME = process.env.REACT_APP_DOMAIN + '/api/volumes/getAllVolumes';
const API_URL_VOLUMES_BY_SPACE = process.env.REACT_APP_DOMAIN + '/api/tags/space';


import authServices from "./authServices";
let config = {};



const API_URL_CHECK_TOKEN = process.env.REACT_APP_DOMAIN + "/api/checkToken";
const getToken = () => {
    const storageToken = localStorage.getItem("token");
    const token = storageToken === (null || undefined) ? '' : storageToken;

    config = {
        headers: {
            "x-auth-token": token,
            "Content-Type": "application/json",
        },
    };

    if (token === (null || undefined)) {
        authServices.logOut();
    }
    try {

        axios.post(API_URL_CHECK_TOKEN, JSON.stringify({}), config).then(res => {
            console.log({ authMsg: res.data.msg })
            if (res.data.msg !== "ok") {
                authServices.logOut();
            }
        }).catch(error => {
            console.error('Error en la petición:', error); // console.log en caso de error
            authServices.logOut();
        })


    } catch (error) {
        authServices.logOut();
    }

};

const getTokenDelete = () => {
    const storageToken = localStorage.getItem("token");
    return storageToken === (null || undefined) ? '' : storageToken;
}

const deleteTag = async (idParent, tagNameToDelete) => {
    try {
        const token = getTokenDelete();
        const response = await axios.delete(API_URL_UPDATE_VOLUMEN, {
            data: { idParent, tagNameToDelete },
            headers: { 'x-auth-token': token, 'Content-Type': 'application/json' }
        });
        return response.data;
    } catch (error) {
        authServices.logOut();
    }
};

const uppdateTagByParentId = async (idParent, matterTagData, previousName) => {
    getToken();
    if (matterTagData.tagName === previousName) {
        previousName = ""
    }
    const response = await axios.patch(API_URL_UPDATE_VOLUMEN, JSON.stringify({
        idParent,
        matterTagData,
        previousName
    }), config);

    return response.data;
};

const addNewTag = async (idParent, matterTagData) => {
    getToken();
    const response = await axios.put(API_URL_UPDATE_VOLUMEN, JSON.stringify({ idParent, matterTagData, }), config);
    return response.data;
};

const getTagsByParentUUID = async (idParent) => {
    getToken();
    const response = await axios.post(API_URL_VOLUMES_BY_PARENT_UUID, JSON.stringify({ idParent }), config);
    return response.data;
};

const updateVolumesPositionByid = async (id, position, rotation, scale) => {
    getToken();
    const response = await axios.put(API_URL_VOLUMES_POSITION, JSON.stringify({ id, position, rotation, scale }), config);
    return response.data;
};

const getTagById = async (id) => {
    getToken();
    const response = await axios.post(API_URL_VOLUME_BY_ID, JSON.stringify({ id }), config);
    return response.data;
};

const getAllTags = async () => {
    getToken();
    const response = await axios.get(API_URL_GET_ALL_VOLUME, config);
    return response.data;
};

const checkName = async (idParent, tagName) => {
    getToken();
    const response = await axios.post(API_URL_CHECK_VOLUME_NAME, JSON.stringify({ idParent, tagName }), config);
    return response.data;
};

const getTagsBySpace = async (space) => {
    try {
        getToken();
        const response = await axios.post(
            API_URL_VOLUMES_BY_SPACE,
            JSON.stringify({ space }),
            config
        );

        // Verifica si el mensaje es "denied"
        if (response.data?.msg === "denied") {
            authServices.logOut();
        }

        return response.data;
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
        // Manejo de otros errores, si es necesario
        authServices.logOut();
        throw error; // Lanza el error para que pueda ser manejado por el código que llame a esta función
    }
};

const tagsServices = {
    getTagsByParentUUID,
    updateVolumesPositionByid,
    getTagById,
    addNewTag,
    getAllTags,
    getTagsBySpace,
    uppdateTagByParentId,
    deleteTag,
    checkName
};

export default tagsServices;
