import {
  FC,
  ReactNode,
  useContext,
  useLayoutEffect,
  useState,
  useEffect,
} from "react";
import useSound from "use-sound";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Button, { IButtonProps } from "../../../components/bootstrap/Button";
import { HeaderRight } from "../../../layout/Header/Header";

import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../../components/bootstrap/Dropdown";
import Icon from "../../../components/icon/Icon";
import LANG, { getLangWithKey, ILang } from "../../../lang";
import useDarkMode from "../../../hooks/useDarkMode";
import Spinner from "../../../components/bootstrap/Spinner";

import { MatterportContext } from "../../../contexts/MatterportContext";
import { OverlayContext } from "../../../contexts/OverlayContext";
import matterportHelper from "../../../helpers/matterportHelper";
import { measurementLinesNode, lastLineID, lastLineItem } from "../../../helpers/matterportHelper";
import IfcSensor from "../../../interfaces/IfcSensor";
import '../../../styles/components/layout/_commonHeaderRight.scss'
import MenuConfigureModalMap from "../_modals/_menuConfigureModalMap";
interface ICommonHeaderRightProps {
  beforeChildren?: ReactNode;
  afterChildren?: ReactNode;
}
interface Item {
  id: number;
  name: string;
}
interface points {
  data: any;

}
interface redrawEvent extends EventInit {
  detail: points;
}
const CommonHeaderRight: FC<ICommonHeaderRightProps> = ({
  beforeChildren,
  afterChildren,
}) => {
  const { darkModeStatus, setDarkModeStatus } = useDarkMode();

  const [sensors, setSensors] = useState<IfcSensor[]>([]);
  const [MatterTags, setMatterTags] = useState<Array<any>>([]);
  const [toogle, setToogle] = useState<boolean>(false);
  let screenShotFlag = true;
  const resolution = {
    width: 1920,
    height: 1080,
  };
  const visibility = {
    mattertags: false,
    sweeps: false,
  };

  const [play] = useSound(matterportHelper.audioShutter, { volume: 0.1 });

  const {
    matterportSdk,
    setActiveMeasure,
    activeMeasure,
    activeSensors,
    setActiveConfigModal
  } = useContext(MatterportContext);

  const { currentSensors } = useContext(OverlayContext);

  const { t } = useTranslation('menu');

  const styledBtn: IButtonProps = {
    color: darkModeStatus ? "dark" : "light",
    hoverShadow: "default",
    isLight: !darkModeStatus,
    size: "lg",
  };

  //let measurementLinesNode: any;
  const { i18n } = useTranslation();

  const changeLanguage = (lng: ILang['key']['lng']) => {
    i18n.changeLanguage(lng);
  };

  /**
   * Language attribute
   */
  useLayoutEffect(() => {
    document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
  });

  const takeScrenshoot = async () => {
    if (screenShotFlag) {
      screenShotFlag = false;
      play();
      matterportSdk.Renderer.takeScreenShot(resolution, visibility).then(
        function (imageB64: any) {
          const nameImage = getNameImage();

          saveImage(imageB64, nameImage);
        }
      );
    }
  };
  const redrawCallback = () => {



  }
  const getNameImage = () => {
    const currentDate = new Date();

    const userLanguage = navigator.language



    let date;

    if (userLanguage.startsWith('en')) {

      date = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } else {

      date = currentDate.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

    }

    const currentTime = `${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()} hrs`;
    const currentBuilding =
      localStorage.getItem("siteName") !== (null && undefined)
        ? localStorage.getItem("siteName")
        : "";

    return `${currentBuilding} --- ${date} --- ${currentTime}.png`;
  };

  const saveImage = (imageBlob: any, nameFile: any) => {
    const tempLink = window.document.createElement("a");

    tempLink.style.display = "none;";
    tempLink.download = nameFile;
    tempLink.href = imageBlob;

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);

    tempLink.remove();

    screenShotFlag = true;
  };

  const toogleTags = (toogle: boolean) => {

    console.log({ toogle, MatterTags });
    matterportSdk.Mattertag.getData().then((res: any) => {
      //res[0].enabled = false;
      //res[0].stemVisible = false;

      //matterportSdk.Mattertag.editBillboard(res[0].sid, { enabled: toogle });
      //console.log({ data: res })
      if (toogle) {
        matterportSdk.Mattertag.add(MatterTags)
      } else {
        setMatterTags(res)
        res.forEach((tag: any) => {
          matterportSdk.Mattertag.remove(tag.sid)
        });
      }

    });
    console.log({ tags: matterportSdk.Mattertag, matterportSdk })



  }
  useEffect(() => {
    if (matterportSdk !== undefined) {
      if (matterportSdk.Scene !== undefined) {
        matterportSdk.Measurements.data.subscribe({
          onAdded: function (index: any, item: any, collection: any) {
            matterportHelper.setLastLineID(index);
            matterportHelper.setLastLineItem(item);
            matterportHelper.loadLineCallBack(matterportSdk, item, index, "m");

          },
          onUpdated: function (index: any, item: any, collection: any) {
            const redrawLinesEvent = new CustomEvent<redrawEvent>('redrawLines',
              {
                detail: item.points
              }
            ); // Crea un objeto de evento
            document.dispatchEvent(redrawLinesEvent);
            if (measurementLinesNode !== undefined) {
              measurementLinesNode.stop();
            }
            matterportHelper.loadLineCallBack(matterportSdk, item, index, "m");
            matterportHelper.setLastLineID(index);
            matterportHelper.setLastLineItem(item);
            console.log({ index, item, collection });
          },
          onCollectionUpdated: function (collection: any) {
            console.log("collectionU", { collection });
            if (matterportHelper.existPropertyName(collection, lastLineID)) {
              console.log({ exisLineID: true })
              if (measurementLinesNode !== undefined) {
                const item = { points: undefined }
                const redrawLinesEvent = new CustomEvent<redrawEvent>('redrawLines',
                  {
                    detail: { detail: { data: lastLineID } }
                  }
                ); // Crea un objeto de evento
                console.log('the entire up-to-date collection', collection);
                document.dispatchEvent(redrawLinesEvent);
                if (measurementLinesNode !== undefined) {
                  measurementLinesNode.stop();
                }

                //matterportHelper.loadLineCallBack(matterportSdk, item);

              }
            } else {
              console.log({ exisLineID: false })

            }


          },
          onRemoved: function (index: any, item: any, collection: any) {
            console.log('item removed from the collection', index, item, collection);
            console.log({ index, lastLineID, collection, lastLine: (Object.keys(collection).length === 0) })
            if (!(Object.keys(collection).length === 0) && !(lastLineID === index)) {
              matterportHelper.loadLineCallBack(matterportSdk, lastLineItem, lastLineID, "m");
            } else {
              const redrawLinesEvent = new CustomEvent<redrawEvent>('redrawLines',
                {
                  detail: { detail: { data: lastLineID } }
                }

              ); // Crea un objeto de evento
              document.dispatchEvent(redrawLinesEvent);
              if (measurementLinesNode !== undefined) {
                measurementLinesNode.stop();
              }

            }

          },
        });
        matterportSdk.Measurements.mode.subscribe(function (
          measurementModeState: any
        ) {
          // measurement mode state has changed
          measurementModeState = measurementModeState.active;
          if (
            measurementModeState === false &&
            measurementLinesNode !== undefined
          ) {
            setActiveMeasure(false);
            if (measurementLinesNode !== undefined) {
              measurementLinesNode.stop();
            }

            //  measurementLinesNode = undefined;
          }
        });
      }
    }
  }, [matterportSdk]);

  useEffect(() => {
    if (matterportSdk !== undefined) {
      matterportHelper
        .onActiveDistance(matterportSdk, {})
        .then((data) => {
          console.log({ DISTANCE: data });
        })
        .catch((err) => {
          console.error(err);
        });
    }
    if (activeMeasure) {
      document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));


    }
  }, [activeMeasure]);

  useEffect(() => {
    if (currentSensors !== undefined && activeSensors) {
      if (sensors.length === 0) {
        currentSensors.forEach((sensor: any, index: any) => {
          matterportHelper
            .looadSensorsCall(sensor, index, matterportSdk)
            .then((res) => {
              const newSensor: IfcSensor = {
                ...res,
              };
              setSensors([...sensors, newSensor]);
            })
            .catch((err) => console.error(err));
        });
      }
      if (sensors.length > 0) {
        sensors.forEach((sensor: any) => {
          sensor.context.root.obj3D.children[0].children[0].visible = true;
        });
      }
    }

    if (
      (activeSensors === false && sensors.length > 0) ||
      currentSensors === undefined
    ) {
      sensors.forEach((sensor: any) => {
        sensor.context.root.obj3D.children[0].children[0].visible = false;
      });
    }
  }, [currentSensors, activeSensors]);

  /**
   * Language attribute
   */
  useLayoutEffect(() => {
    document.documentElement.setAttribute(
      "lang",
      i18n.language.substring(0, 2)
    );
  });

  const isSite = () => {
    if (window.location.pathname === `/${"space"}`) {
      return true;
    }
    return false;
  };

  const setCol12Span = () => {

    const storageRol = localStorage.getItem('role');

    if (storageRol !== undefined && storageRol === 'Admin') return false;

    return true;

  };
  return (
    <HeaderRight>
      <div className="row g-3">
        {beforeChildren}

        {/* Lang Selector */}
        <div className='col-auto'>
          <Dropdown>
            <DropdownToggle hasIcon={false}>
              {typeof getLangWithKey(i18n.language as ILang['key']['lng'])?.icon ===
                'undefined' ? (
                <Button
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...styledBtn}
                  className='btn-only-icon'
                  aria-label='Change language'
                  data-tour='lang-selector'>
                  <Spinner isSmall inButton='onlyIcon' isGrow />
                </Button>
              ) : (
                <Button
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...styledBtn}
                  icon={
                    getLangWithKey(i18n.language as ILang['key']['lng'])?.icon
                  }
                  aria-label='Change language'
                  data-tour='lang-selector'
                />
              )}
            </DropdownToggle>
            <DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
              {Object.keys(LANG).map((i) => (
                <DropdownItem key={LANG[i].lng}>
                  <Button
                    icon={LANG[i].icon}
                    onClick={() => changeLanguage(LANG[i].lng)}>
                    {LANG[i].text}
                  </Button>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>

        {/* Quick Panel */}
        {isSite() ? (
          <div className="col-auto">
            <Dropdown>
              <DropdownToggle hasIcon={false}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading 
							
							*/}
                <Button {...styledBtn} icon="Build" aria-label="Quick menu" />
              </DropdownToggle>
              <DropdownMenu
                isAlignmentEnd
                size="lg"
                className="py-0 overflow-hidden"
              >
                <div className="row g-0">
                  <div
                    className={classNames(
                      "col-12",
                      "p-4",
                      "d-flex justify-content-center",
                      "fw-bold fs-5",
                      "text-info",
                      "border-bottom border-info",
                      {
                        "bg-l25-info": !darkModeStatus,
                        "bg-lo25-info": darkModeStatus,
                      }
                    )}
                  >
                    {t("Tools") as ReactNode}
                  </div>
                  <div
                    className={classNames(
                      "col-6 p-4 transition-base cursor-pointer bg-light-hover",
                      { "border-dark": darkModeStatus }
                    )}
                  >
                    <div
                      className="d-flex flex-column align-items-center justify-content-center"
                      onClick={() => {
                        setActiveMeasure(!activeMeasure)

                        //  matterportHelper.checkExistElemenOverlay();
                        const overlay = document.getElementById('showcase');
                        matterportHelper.validateProtectedModel(overlay);
                      }

                      }
                    >
                      <Icon icon="Straighten" size="3x" color="info" />
                      <span>{t("Measurement") as ReactNode}</span>
                      {/* <small className="text-muted">Options</small> */}
                    </div>
                  </div>

                  <div
                    className="col-6 p-4 transition-base cursor-pointer bg-light-hover"
                    onClick={() => {
                      if (matterportSdk !== undefined) {
                        takeScrenshoot();
                      }
                    }}
                  >
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <Icon icon="Monitor" size="3x" color="warning" />
                      <span>{t("Screenshot") as ReactNode}</span>
                      {/* <small className="text-muted">Mode</small> */}
                    </div>
                  </div>
                  <div
                    className={`${setCol12Span() ? 'col-12' : 'col-6'} p-4 transition-base cursor-pointer bg-light-hover`}
                    onClick={() => {
                      if (matterportSdk !== undefined) {
                        matterportSdk.Mode.moveTo(
                          matterportSdk.Mode.Mode.FLOORPLAN
                        );
                      }
                    }}
                  >
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <Icon icon="map" size="3x" color="success" />
                      <span>{t("PlanView") as ReactNode}</span>
                      {/* <small className="text-muted">Mode</small> */}
                    </div>
                  </div>
                  <button
                    className={`${setCol12Span() ? 'hidden' : 'col-6'} buttonUploadMap p-4 transition-base cursor-pointer bg-light-hover`}
                    onClick={() => setActiveConfigModal(true)}
                  >
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <Icon icon="map" size="3x" color="success" />
                      <span className="spanUploadMap">{t("UploadMap") as ReactNode}</span>
                      {/* <small className="text-muted">Mode</small> */}
                    </div>
                  </button>
                  {/**Activar tags */}
                  <div
                    className={`${setCol12Span() ? 'col-12' : 'col-6'} p-4 transition-base cursor-pointer bg-light-hover`}
                    onClick={() => {
                      if (matterportSdk !== undefined) {
                        setToogle(!toogle)
                        toogleTags(toogle);
                      }
                    }}
                  >
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <Icon icon="LocalOffer" size="3x" color="success" />
                      <span>{"Tags" as ReactNode}</span>
                      {/* <small className="text-muted">Mode</small> */}
                    </div>
                  </div>
                  <button
                    className={`${setCol12Span() ? 'hidden' : 'col-6'} buttonUploadMap p-4 transition-base cursor-pointer bg-light-hover`}

                  >

                  </button>
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
        ) : null}

        {afterChildren}
      </div>
      <MenuConfigureModalMap></MenuConfigureModalMap>
    </HeaderRight>

  );
};
CommonHeaderRight.propTypes = {
  beforeChildren: PropTypes.node,
  afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
  beforeChildren: null,
  afterChildren: null,
};

export default CommonHeaderRight;
