import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { MatterportContext } from '../contexts/MatterportContext'
import { OverlayContext } from '../contexts/OverlayContext';
import Button, { ButtonGroup } from './bootstrap/Button';
import modelsServices from '../services/modelsServices.js';
import { useTranslation } from 'react-i18next';
import matterportHelper from '../helpers/matterportHelper';
import volumenServices from '../services/volumesServices';
import ThemeContext from '../contexts/themeContext';
import { volumeTagList, modelsTagList } from '../helpers/matterportHelper';
function ObjectManager() {

    const {
        fbxObjects,
        currentVolumes,
        setCurrentVolumes,
        matterportSdk,
        setFbxObjects,
        modelList
    } = useContext(MatterportContext);
    const { mobileDesign } = useContext(ThemeContext);
    const { setSensorsIsActive,
        sensorsIsActive,
        setCurrentNodeSelected,
        currentNodeSelected,
        modalState,
        setModalState,
        editModel
    } = useContext(OverlayContext);

    const [buttonSelection, setButtonSelection] = useState({
        isActiveModels: false,
        isActiveSensors: false,
        isActiveVolumes: false,
    });

    const [mounted, setMounted] = useState(false);
    const [modelsInitialLoad, setModelsInitialLoad] = useState(true);
    const [volumesInitialLoad, setVolumesInitialLoad] = useState(true);
    const [positionEventState, setPositionEventState] = useState(false);
    const [MatterTags, setMatterTags] = useState([]);
    const [toogle, setToogle] = useState(false);

    const toogleTags = (toogle) => {
        console.log({ toogle });
        console.log({ volumeTags: volumeTagList })
        console.log({ modelsTagList })
        t
        matterportSdk.Mattertag.getData().then((res) => {
            //res[0].enabled = false;
            //res[0].stemVisible = false;
            //matterportSdk.Mattertag.editBillboard(res[0].sid, { enabled: toogle });
            //console.log({ data: res })
            if (toogle) {
                matterportSdk.Mattertag.add(MatterTags)
            } else {

                // Crear un conjunto con los tagId para búsqueda rápida
                setMatterTags(res)
                res.forEach((tag) => {
                    matterportSdk.Mattertag.remove(tag.sid)
                });
                const tagIdSet = new Set(currentVolumes.map(item => item.data.node?.tagId[0]));

                // Filtrar los objetos de arr2 cuyos sid estén en el set de tagId
                const result = res.filter(item => tagIdSet.has(item.sid));

                console.log({ res, currentVolumes, result })

                //console.log(result);

            }
            console.log({ tags: matterportSdk.Mattertag, matterportSdk })
        });
    }

    const [space, setSpace] = useState(undefined);

    const { t } = useTranslation('menu');

    //Cuando se cambia de nodo en jerarquía 
    const nodeEventHandler = (event) => {

        // Do something with the event data
        if (event.detail !== undefined) {
            console.log({ eventNodeOBjects: event.detail, currentNodeSelected })
            setCurrentNodeSelected(event.detail)


        }
    };

    const positionEventHandler = (event) => {

        setPositionEventState(true);

    };

    async function LoadVolumesById(id) {
        const role = localStorage.getItem("role");
        console.log({ currentVolumes })
        await new Promise((resolve) => {
            volumenServices.getVolumesByParentUUID(id).then(res => {
                if (res.volumens.length > 0 && matterportSdk !== undefined) {
                    console.log({ volumes: res })
                    for (let i = 0; i < res.volumens.length; i++) {

                        const existModel = matterportHelper.checkIfVolumenExistByName(currentVolumes, res.volumens[i].volumeName + "/" + res.volumens[i].parentId);
                        //   console.log({ volumeName: res.volumens[i].volumen.volumeName + "/" + res.volumens[i].parentId, existModel })
                        if (!existModel) {
                            setTimeout(() => {
                                if (matterportSdk !== undefined && res.volumens[i].objectTransform !== "") {
                                    matterportHelper.emptyModelLoader({
                                        animated: 0,
                                        id: i,
                                        interactuable: res.volumens[i].interactionType,
                                        length: 1,
                                        interactuable_content: res.volumens[i].interactionReference,
                                        interactuable_type: "text",
                                        modelfilename: "CuboTransparente.fbx",
                                        modelformat: "fbx",
                                        parent: currentNodeSelected._id,
                                        positionx: res.volumens[i].objectTransform.xPosition,
                                        positiony: res.volumens[i].objectTransform.yPosition,
                                        positionz: res.volumens[i].objectTransform.zPosition,
                                        rotationx: res.volumens[i].objectTransform.xRotation,
                                        rotationy: res.volumens[i].objectTransform.yRotation,
                                        rotationz: res.volumens[i].objectTransform.zRotation,
                                        scalex: res.volumens[i].objectTransform.xScale,
                                        scaley: res.volumens[i].objectTransform.yScale,
                                        scalez: res.volumens[i].objectTransform.zScale

                                    }, matterportSdk, role)
                                        .then((data) => {
                                            data.name = currentNodeSelected._id + "/" + res.volumens[i].volumeName;


                                            setCurrentVolumes(current => [...current, { data: { node: data } }]);
                                            //     i++;
                                        })
                                        .catch((err) => {
                                            console.error(err);
                                            //  i++;
                                        });
                                }
                            }, 1500);
                        } else {
                            // i++;
                        }



                    }

                    //  setFbxObjects(current => [...current, { data }]);
                }
            }).catch(err => {
                console.error(err)
                resolve({ err });
            })
        })

    }
    async function LoadSpaceVolumes(space) {
        const role = localStorage.getItem("role");
        console.log({ currentVolumes })
        await new Promise((resolve) => {
            volumenServices.getVolumesBySpace(space).then(res => {
                if (res.volumens.length > 0 && matterportSdk !== undefined) {
                    console.log({ volumes: res })
                    for (let i = 0; i < res.volumens.length; i++) {
                        const existModel = matterportHelper.checkIfVolumenExistByName(currentVolumes, res.volumens[i].parentId + "/" + res.volumens[i].volumen.volumeName);
                        //console.log({ volumeName: res.volumens[i].volumen.volumeName + "/" + res.volumens[i].parentId, existModel })
                        if (!existModel) {
                            setTimeout(() => {
                                if (matterportSdk !== undefined && res.volumens[i].objectTransform !== "") {
                                    const voluemeParent = res.volumens[i].parentId;
                                    matterportHelper.emptyModelLoader({
                                        animated: 0,
                                        id: i,
                                        interactuable: res.volumens[i].volumen.interactionType,
                                        length: 1,
                                        interactuable_content: res.volumens[i].volumen.interactionReference,
                                        interactuable_type: "text",
                                        modelfilename: "CuboTransparente.fbx",
                                        modelformat: "fbx",
                                        parent: voluemeParent,
                                        positionx: res.volumens[i].volumen.objectTransform.xPosition,
                                        positiony: res.volumens[i].volumen.objectTransform.yPosition,
                                        positionz: res.volumens[i].volumen.objectTransform.zPosition,
                                        rotationx: res.volumens[i].volumen.objectTransform.xRotation,
                                        rotationy: res.volumens[i].volumen.objectTransform.yRotation,
                                        rotationz: res.volumens[i].volumen.objectTransform.zRotation,
                                        scalex: res.volumens[i].volumen.objectTransform.xScale,
                                        scaley: res.volumens[i].volumen.objectTransform.yScale,
                                        scalez: res.volumens[i].volumen.objectTransform.zScale

                                    }, matterportSdk, role)
                                        .then((data) => {
                                            data.name = voluemeParent + "/" + res.volumens[i].volumen.volumeName;
                                            data.mattertag = "";
                                            data.type = "volume";
                                            matterportHelper.createMattertag({ model: { node: data } }, matterportSdk).then((mattertagId) => {
                                                data.tagId = mattertagId;
                                            }).catch((error) => {
                                                console.error('Error al crear Mattertag:', error);
                                            });

                                            console.log({ volumeData: data });
                                            setCurrentVolumes(current => [...current, { data: { node: data } }]);
                                            //     i++;


                                        })
                                        .catch((err) => {
                                            console.error(err);
                                            //  i++;
                                        });


                                }
                            }, 1500);
                        } else {
                            // i++;
                        }



                    }

                    //  setFbxObjects(current => [...current, { data }]);
                }
            }).catch(err => {
                console.error(err)
                resolve({ err });
            })
        })

    }
    async function cargarobjectos(array) {

        console.log({ array })

        //console.log({ element })
        for (let i = 0; i < array.length; i++) {




            console.log({ modelList })
            const foundModel = modelList.find(model => model.id === array[i].modelOID);

            const existModel = matterportHelper.checkIfModelExistByName(fbxObjects, currentNodeSelected._id + "/" + array[i].modelName)
            if (!existModel) {

                const data = await matterportHelper.loadModelMongo(matterportSdk, {
                    mongoName: currentNodeSelected._id + "/" + array[i].modelName,
                    animated: 0,
                    id: i,
                    interactuable: array[i].interactionType,
                    length: 1,
                    interactuable_content: array[i].interactionReference,
                    interactuable_type: "text",
                    modelfilename: foundModel ? foundModel.name : array[i].modelFileName,
                    modelformat: array[i].modelFile.substring(array[i].modelFile.lastIndexOf(".") + 1),
                    parent: currentNodeSelected._id,
                    positionx: array[i].objectTransform.xPosition,
                    positiony: array[i].objectTransform.yPosition,
                    positionz: array[i].objectTransform.zPosition,
                    rotationx: array[i].objectTransform.xRotation,
                    rotationy: array[i].objectTransform.yRotation,
                    rotationz: array[i].objectTransform.zRotation,
                    scalex: array[i].objectTransform.xScale,
                    scaley: array[i].objectTransform.yScale,
                    scalez: array[i].objectTransform.zScale

                })
                data.name = currentNodeSelected._id + "/" + array[i].modelName;
                data.mattertag = "";
                console.log({ fbxObjects });
                data.node.type = "model"
                matterportHelper.createMattertag({ model: data }, matterportSdk).then((mattertagId) => {
                    data.tagId = mattertagId;
                }).catch((error) => {
                    console.error('Error al crear Mattertag:', error);
                });
                setFbxObjects(current => [...current, { data }]);



                //  i++;
            }

        }


    }
    async function LoadSpacesModels(space) {
        await new Promise((resolve) => {
            modelsServices.getModelsBySpace(space).then(res => {
                if (res.models.length > 0 && matterportSdk !== undefined) {
                    cargarobjectos(res.models);


                    //  setFbxObjects(current => [...current, { data }]);
                }
            }).catch(err => {
                console.error(err)
                resolve({ err });
            })
        })
    }

    async function LoadModelsById(id) {
        await new Promise((resolve) => {
            modelsServices.getModelsByParentUUID(id).then(res => {
                if (res.models.length > 0 && matterportSdk !== undefined) {
                    cargarobjectos(res.models);


                    //  setFbxObjects(current => [...current, { data }]);
                }
            }).catch(err => {
                console.error(err)
                resolve({ err });
            })
        })
    }
    function cleanScene() {
        matterportHelper.deleteAllSceneObjects(fbxObjects).then(() => {
            setFbxObjects([])
            setModelsInitialLoad(true)
        });
        /*
        matterportHelper.deleteAllSceneObjects(currentVolumes).then(() => {
            setCurrentVolumes([])
            setVolumesInitialLoad(true)
        });
        */
        setButtonSelection((prev) => ({
            ...prev, // Copiar todas las propiedades existentes
            isActiveModels: false,
            // isActiveVolumes: false
        }));
    }
    async function AciveModels() {

        setTimeout(() => {
            setButtonSelection((prev) => ({
                ...prev, // Copiar todas las propiedades existentes
                isActiveModels: false// Actualizar solo esta propiedad
            }));
            console.log({ mounted, matterportSdk, space, modelsInitialLoad, fbxObjects, button: buttonSelection.isActiveModels })
            if (buttonSelection.isActiveModels === false) {
                console.log("A");
                console.log({ fxbObjectsM: fbxObjects })
                if (mounted && matterportSdk !== undefined && space !== undefined) {
                    console.log("A");
                    if (modelsInitialLoad === true) {
                        console.log("B");
                        setModelsInitialLoad(false);
                        if (currentNodeSelected !== undefined) {
                            console.log("C");
                            LoadModelsById(currentNodeSelected._id)
                                .catch(err => {
                                    console.err({ err })
                                })

                        } else {

                        }

                    } else {
                        console.log("D");
                        matterportHelper.turnOnAllCurrentFbxObjects(fbxObjects)
                    }
                }
            } else if (fbxObjects !== undefined) {
                console.log("E");
                matterportHelper.turnOffAllCurrentFbxObjects(fbxObjects);
            }
            console.log("Tiempo terminado")
            //alert("ACIVE");
        }, 6000);
    }
    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            window.addEventListener("hierarchyNodeEvent", nodeEventHandler);
            window.addEventListener("positionEvent", positionEventHandler);
            setSpace(localStorage.getItem("space"))
        }
    }, [mounted]);

    useEffect(() => {
        if (sensorsIsActive === false && buttonSelection.isActiveSensors === true) {
            setButtonSelection((prev) => ({
                ...prev, // Copiar todas las propiedades existentes
                isActiveSensors: false
            }));
        }
    }, [sensorsIsActive]);

    useEffect(() => {
        if (currentNodeSelected !== undefined && !currentNodeSelected.isVolumeEvent) {
            console.log({ idCLEAN: currentNodeSelected })
            if (currentNodeSelected._id !== currentNodeSelected.previuos) {
                cleanScene();

            }
            //  AciveModels()
        }
    }, [currentNodeSelected])
    useEffect(() => {
        console.log({ positionEventState })
        if (positionEventState) {
            //  AciveModels()
            setPositionEventState(false)
        }
    }, [positionEventState])


    return (
        <ButtonGroup
            className={mobileDesign ? "btn-group-vertical" : ""}
            id='actionOptionMatterport'>
            <Button
                color="primary"
                icon="View_in_ar"
                isActive={buttonSelection.isActiveModels}
                onClick={() => {

                    setButtonSelection((prev) => ({
                        ...prev, // Copiar todas las propiedades existentes
                        isActiveModels: !buttonSelection.isActiveModels// Actualizar solo esta propiedad
                    }));
                    console.log({ mounted, matterportSdk, space, modelsInitialLoad, fbxObjects, button: buttonSelection.isActiveModels })
                    if (buttonSelection.isActiveModels === false) {
                        console.log({ fxbObjectsM: fbxObjects })
                        if (mounted && matterportSdk !== undefined && space !== undefined) {
                            if (modelsInitialLoad === true) {

                                setModelsInitialLoad(false);
                                if (currentNodeSelected !== undefined) {
                                    LoadModelsById(currentNodeSelected._id)
                                        .catch(err => {
                                            console.err({ err })
                                        })

                                } else {

                                }

                            } else {
                                matterportHelper.turnOnAllCurrentFbxObjects(fbxObjects)
                                // toogleTags(true, matterportSdk);
                            }
                        }
                    } else if (fbxObjects !== undefined) {
                        //toogleTags(false, matterportSdk);
                        matterportHelper.turnOffAllCurrentFbxObjects(fbxObjects);
                    }


                }}
            >
                {t("Models")}
            </Button>
            <Button
                color="primary"
                icon="Sensors"
                isActive={buttonSelection.isActiveSensors}
                onClick={() => {
                    setSensorsIsActive(!sensorsIsActive)
                    setButtonSelection((prev) => ({
                        ...prev, // Copiar todas las propiedades existentes
                        isActiveSensors: !buttonSelection.isActiveSensors// Actualizar solo esta propiedad
                    }));
                }}
            >
                {t("Sensors")}
            </Button>
            <Button
                id="btnTurnOffVolumens"
                color="primary"
                icon="border_clear"
                isActive={buttonSelection.isActiveVolumes}
                onClick={() => {

                    setButtonSelection((prev) => ({
                        ...prev, // Copiar todas las propiedades existentes
                        isActiveVolumes: !buttonSelection.isActiveVolumes// Actualizar solo esta propiedad
                    }));

                    if (currentVolumes !== undefined && buttonSelection.isActiveVolumes === false) {
                        if (mounted && matterportSdk !== undefined && space !== undefined) {
                            if (volumesInitialLoad === true  /*buttonSelection.isActiveVolumes*/) {
                                setVolumesInitialLoad(false);
                                LoadSpaceVolumes(space)
                                    .catch(err => {
                                        console.err({ err })
                                    })
                                /*
                                LoadVolumesById(space)
                                .catch(err => {
                                console.err({ err })
                                })*/
                            } else {
                                console.log({
                                    currentVolumes
                                })
                                matterportHelper.turnOnAllCurrentVolumens(currentVolumes)
                                //       toogleTags(true, matterportSdk);
                            }
                        }
                    } else if (currentVolumes !== undefined) {
                        matterportHelper.turnOffAllCurrentVolumens(currentVolumes);
                        // 
                        // toogleTags(false, matterportSdk);
                        if (editModel !== undefined) {
                            console.log({ editModel })
                            editModel.control.nodeControl.obj3D.visible = false;
                        }
                    }
                    // matterportHelper.turnOffAllCurrentVolumens(currentVolumes);
                }}
            >
                {t("Volumes")}
            </Button>
            <Button
                id="btnTurnOffTags"
                color="primary"
                icon="LocalOffer"
                //isActive={buttonSelection.isActiveVolumes}
                onClick={() => {
                    setToogle(!toogle)
                    toogleTags(toogle);
                }}
            >
                {t("Tags")}
            </Button>
        </ButtonGroup>
    )
}

export default ObjectManager