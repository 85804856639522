
let test = "";

function Box() {
    this.inputs = {
        visible: false,
    };

    this.onInit = function () {
        var THREE = this.context.three;
        var Scene = this.context.scene;
        var geometry = new THREE.BoxGeometry(10, 10, 10);
        this.material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });

        var mesh = new THREE.Mesh(geometry, this.material);

        console.log({ customBox: mesh })
        this.outputs.objectRoot = mesh;   // gets added to the scene node
        this.outputs.collider = mesh;
    };

    this.onEvent = function (type, data) {
    }

    this.onInputsUpdated = function (previous) {
    };

    this.onTick = function (tickDelta) {
    }

    this.onDestroy = function () {
        this.material.dispose();
    };
}

function BoxFactory() {
    return new Box();
}
export default {
    BoxFactory
};